import React from "react";
import "../styles/style.css";
import { Grid, Button } from "@mui/material";
import Favicon_NoBg from "../assets/Images/touchscreen12.png";
import ProductImage from "../assets/Images/productimage.png";
import Arrowright from "../assets/Images/arrow-right.png";
import SmashProof from "../assets/Images/shield-tick.svg";
import Scanner from "../assets/Images/scanner.svg";
import Security from "../assets/Images/security.svg";
import Lock from "../assets/Images/lock.svg"; 

const Product = (props) => {
  return (
    <div className="containerProduct" id="productSection">
      <div className="product">
        <Grid container className="product-content">
          <Grid item container xs={6}>
            <p className="OverProduct">Our Product</p>
          </Grid>
          <Grid item xs={6} container className="item7" style={{position:"relative"}}>
            <img src={Favicon_NoBg} alt="SVGIcon" className="logo2" />
            {/* <p className="touch1">TOUGH</p>
            <p className="screen1">SCREEN</p> */}
          </Grid>
        </Grid>
        <Grid className="imagesectionproduct">
          <img src={ProductImage} alt="productsectionimage" width={"100%"} />
        </Grid>
        <Grid className="imagesectionproduct">
          <Button
            className="findoutmore"
            onClick={() => props.handleOpencustomModal()}
          >
            <p style={{ margin: 0, flexGrow: 1 }}>FIND OUT MORE</p>
            <img src={Arrowright} alt="Arrowright" className="rightArrow" />
          </Button>
        </Grid>
      </div>
      <Grid container className="product2">
        <Grid item container xs={12} lg={4}>
          <p className="partition">
            Partition screens that offer shielding protection
          </p>
          <Button onClick={() => props.handleOpenModal()} className="ordernow2 dispayResponse">
            ORDER NOW
          </Button>
        </Grid>
        <Grid item xs={12} lg={8} container className="item7" spacing={1.4}>
          <Grid item xs={6} sm={3} container justifyContent={"center"}>
            <Grid className="icon_container">
              <Grid style={{ textAlign: "center" }}>
                <img className="icon_custom" src={Security} alt="Security" />
                <p className="OverProduct2">Robust</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3} container justifyContent={"center"}>
            <Grid className="icon_container">
              <Grid style={{ textAlign: "center" }}>
                <img className="icon_custom" src={Scanner} alt="Scanner" />
                <p className="OverProduct2">Impact Resistant</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3} container justifyContent={"center"}>
            <Grid className="icon_container">
              <Grid style={{ textAlign: "center" }}>
                <img
                  className="icon_custom"
                  src={SmashProof}
                  alt="SmashProof"
                />
                <p className="OverProduct2">Smash Proof</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3} container justifyContent={"center"}>
            <Grid className="icon_container">
              <Grid style={{ textAlign: "center" }}>
                <img className="icon_custom" src={Lock} alt="Lock" />
                <p className="OverProduct2">Safe</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Button onClick={() => props.handleOpenModal()} className="ordernow2 dispayhide">
            ORDER NOW
          </Button>
      </Grid>
    </div>
  );
};

export default Product;
